<template>
  <v-text-field v-model="qty" @change="setQty(undefined)" dense flat outlined hide-details style="width: 100px"/>
</template>

<script>
export default {
  name: "InvoiceQty",
  props: ['value','decimals'],
  data () {
    return {
      qty: 1
    }
  },
  watch: {
    value: {
      immediate: true,
      handler (val) {
        this.setQty(val)
      }
    }
  },
  methods: {
    setQty (val) {
      if (typeof val === "undefined") {
        val = this.qty
      }
      if (val === null) {
        val = 0
      }
      if (val.trim) {
        val = val.trim()
      }
      if (this.decimals) {
        if (/^-?[0-9]+(?:\.[0-9]*)?$/.test(val)) {
          this.qty = parseFloat(val).toFixed(this.decimals)
        } else {
          this.qty = 0
        }
      } else {
        this.qty = Number.isInteger(parseInt(val)) ? parseInt(val) : 0
      }
      this.$emit('input', this.qty)
    }
  }
}
</script>

<style scoped>

</style>
