<template>
  <div>
    Qty: {{ totalQty }}<span v-for="shelf in this.product.shelfs.filter(x => x.qty > 0)" :key="shelf.id">, {{ shelf.name }}: {{ shelf.qty }}</span>
  </div>
</template>

<script>
export default {
  name: "ProductQty",
  props: ['product'],
  computed: {
    totalQty () {
      return this.product.shelfs.reduce((sum, shelf) => sum + shelf.qty, 0)
    }
  }
}
</script>

<style scoped>

</style>
