<template>
  <div class="pa-10">
    <v-expansion-panels class="mb-5">
      <v-expansion-panel>
        <v-expansion-panel-header>
          Business address and contact details, title, summary, and logo
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div class="d-flex">
            <v-text-field
              v-model="invoice.title"
              label="Invoice title"
              outlined
              flat
            />
          </div>
          <div class="d-flex">
            <v-text-field
              v-model="invoice.subhead"
              label="Summary"
              outlined
              flat
              dense
            />
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-card class="mb-5">
      <div class="d-flex pa-5">
        <div>
          <div class="font-weight-black blue-grey--text">Bill to:</div>
          <div v-if="invoice.customer">
            <div>
              <strong>{{ invoice.customer.name }}</strong>
            </div>
            <div>
              {{ invoice.customer.firstName }} {{ invoice.customer.lastName }}
            </div>
            <AddressRender :address="invoice.customer.address" />
            <div class="mt-3">{{ invoice.customer.phone }}</div>
            <div>{{ invoice.customer.email }}</div>
          </div>
          <div v-else>
            <v-icon x-large class="pa-10">mdi-account-alert</v-icon>
          </div>
          <div class="d-flex">
            <v-btn
              v-if="invoice.customer"
              outlined
              @click="
                editCustomer.customer = invoice.customer;
                editCustomer.show = true;
              "
            >
              <v-icon class="mr-2">mdi-pencil</v-icon>
              edit customer
            </v-btn>
            <v-btn outlined @click="addCustomer.show = true">
              <v-icon class="mr-2"
                >mdi-{{
                  invoice.customer ? "refresh - circle" : "plus"
                }}</v-icon
              >
              {{ invoice.customer ? "change" : "add" }} customer
            </v-btn>
          </div>
        </div>
        <div v-if="invoice.customer && invoice.customer.shippingDetails">
          <div class="font-weight-black blue-grey--text">Ship to:</div>
          <div>
            <strong>{{ invoice.customer.shippingDetails.name }}</strong>
          </div>
          <AddressRender :address="invoice.customer.shippingDetails.address" />
          <div class="mt-3">{{ invoice.customer.shippingDetails.phone }}</div>
          <div>{{ invoice.customer.shippingDetails.instructions }}</div>
        </div>
        <div class="px-10">
          <v-checkbox
            v-model="invoice.differentShippingAddress.different"
            label="Different shipping address"
          />
          <div v-if="invoice.differentShippingAddress.different">
            <div
              v-if="
                !invoice.differentShippingAddress.change &&
                  invoice.differentShippingAddress.address &&
                  invoice.differentShippingAddress.name
              "
            >
              {{ invoice.differentShippingAddress.name }}
              {{ invoice.differentShippingAddress.phone }}
              <AddressRender
                :address="invoice.differentShippingAddress.address"
              />
            </div>
            <div v-else class="text--secondary py-3">No address</div>
            <v-card>
              <v-card-text v-if="invoice.differentShippingAddress.change">
                <v-text-field
                  v-model="invoice.differentShippingAddress.name"
                  label="Name"
                />
                <v-text-field
                  v-model="invoice.differentShippingAddress.phone"
                  label="Phone"
                />
                <CustomerAddress
                  v-model="invoice.differentShippingAddress.address"
                />
              </v-card-text>
            </v-card>
            <v-btn
              @click="
                $set(
                  invoice.differentShippingAddress,
                  'change',
                  !invoice.differentShippingAddress.change
                )
              "
              >{{
                invoice.differentShippingAddress.change ? "Save" : "Change"
              }}</v-btn
            >
          </div>
        </div>
        <v-spacer />
        <div>
          <table>
            <tr>
              <td>Invoice number</td>
              <td>
                <v-text-field
                  v-model="invoice.invoiceNumber"
                  outlined
                  hide-details
                  dense
                  flat
                />
              </td>
            </tr>
            <tr>
              <td>P.O./S.O. number</td>
              <td>
                <v-text-field
                  v-model="invoice.poNumber"
                  outlined
                  hide-details
                  dense
                  flat
                />
              </td>
            </tr>
            <tr>
              <td>Invoice date</td>
              <td>
                <v-text-field
                  type="date"
                  v-model="invoice.invoiceDate"
                  outlined
                  hide-details
                  dense
                  flat
                />
              </td>
            </tr>
            <tr>
              <td>Payment due</td>
              <td>
                <v-text-field
                  type="date"
                  v-model="invoice.dueDate"
                  outlined
                  hide-details
                  dense
                  flat
                />
              </td>
            </tr>
          </table>
        </div>
      </div>
      <v-simple-table>
        <thead>
          <tr class="blue-grey lighten-4">
            <th></th>
            <th style="width: 100%">Items</th>
            <th>Quantity</th>
            <th>Price</th>
            <th>Amount</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, itemK) in invoice.items"
            :key="itemK"
            :class="{
              'blue-grey lighten-5': itemK % 2 === 1
            }"
          >
            <td>{{ itemK + 1 }}.</td>
            <td>
              <div class="mt-3">
                <strong>
                  {{ item.name }}
                </strong>
                <ProductQty
                  v-if="
                    item.product &&
                      newItemDialog.products
                        .map(x => x.id)
                        .includes(item.product)
                  "
                  :product="
                    newItemDialog.products.filter(x => x.id === item.product)[0]
                  "
                />
              </div>
              <div class="my-3">
                <v-text-field
                  flat
                  hide-details
                  dense
                  outlined
                  label="Description"
                  v-model="item.description"
                />
              </div>
              <div v-if="item.shipping" class="my-3">
                <div v-if="item.shippingDimensionsShow" class="py-3">
                  <v-card outlined class="pa-5" color="blue-grey lighten-4">
                    <ShipmentSize
                      :value="item.shippingDimensions"
                      @input="$set(item, 'shippingDimensions', arguments[0])"
                    />
                    <div class="text-center mt-3">
                      <v-btn
                        depressed
                        color="blue-grey"
                        dark
                        @click="calculateShipping(item)"
                        >Calculate cost</v-btn
                      >
                    </div>
                    <v-simple-table
                      v-if="
                        item.shippingChoices && item.shippingChoices.length > 0
                      "
                      class="mt-5"
                    >
                      <thead>
                        <tr>
                          <th>Service</th>
                          <th>Price</th>
                          <th>Negotiated price</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(sc, sck) in item.shippingChoices"
                          :key="sck"
                        >
                          <td>{{ sc.name }}</td>
                          <td>{{ sc.price.toFixed(2) }}</td>
                          <td>{{ sc.negotiated.toFixed(2) }}</td>
                          <td>
                            <v-btn
                              small
                              depressed
                              @click="
                                item.price = sc.price;
                                item.description = sc.name;
                                item.shippingChoices = [];
                                item.shippingDimensions.service = sc.service;
                                item.shippingDimensionsShow = false;
                              "
                              >Select</v-btn
                            >
                          </td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                  </v-card>
                </div>
                <a
                  href="#"
                  @click="
                    item.shippingDimensionsShow = !item.shippingDimensionsShow
                  "
                >
                  {{ item.shippingDimensionsShow ? "Hide" : "Show" }} calculator
                </a>
              </div>
              <v-sheet
                outlined
                v-if="item.eans.length > 0"
                class="my-3 pa-2"
                color="blue-grey lighten-5"
              >
                Select barcode:
                <div>
                  <v-chip
                    v-for="(ean, eanK) in item.eans"
                    :key="eanK"
                    :outlined="!ean.selected"
                    class="mr-2 mb-2"
                    :color="ean.selected ? 'green' : 'grey'"
                    :text-color="ean.selected ? 'white' : 'black'"
                    @click="
                      item.eans.map(x => (x.selected = false));
                      ean.selected = true;
                    "
                    >{{ ean.name ? ean.name + ": " : "" }}{{ ean.ean }}</v-chip
                  >
                </div>
              </v-sheet>
            </td>
            <td>
              <InvoiceQty v-model="item.quantity" />
            </td>
            <td>
              <InvoiceQty v-model="item.price" decimals="2" />
            </td>
            <td class="text-right text-no-wrap">
              {{ (item.quantity * item.price).toFixed(2) }}
              {{ invoice.currency }}
            </td>
            <td class="text-no-wrap text-right">
              <v-btn
                v-if="itemK > 0"
                @click="
                  invoice.items.splice(
                    itemK - 1,
                    2,
                    invoice.items[itemK],
                    invoice.items[itemK - 1]
                  )
                "
                icon
                class=""
                ><v-icon>mdi-arrow-up</v-icon></v-btn
              >
              <v-btn
                v-if="itemK < invoice.items.length - 1"
                @click="
                  invoice.items.splice(
                    itemK,
                    2,
                    invoice.items[itemK + 1],
                    invoice.items[itemK]
                  )
                "
                icon
                class=""
                ><v-icon>mdi-arrow-down</v-icon></v-btn
              >
              <v-btn @click="invoice.items.splice(itemK, 1)" icon class=""
                ><v-icon>mdi-delete</v-icon></v-btn
              >
            </td>
          </tr>
          <tr>
            <td colspan="6" class="text-center pa-3">
              <v-btn
                depressed
                class="blue--text white ma-2"
                @click="newItemDialog.show = true"
              >
                <v-icon class="mr-3">mdi-plus-circle-outline</v-icon>
                Add an item
              </v-btn>
              <v-btn
                depressed
                class="blue--text white ma-2"
                @click="invoice.items.push(shippingBase())"
              >
                <v-icon class="mr-3">mdi-truck-outline</v-icon>
                Add shipping
              </v-btn>
            </td>
          </tr>
          <tr>
            <td colspan="4" class="text-right">
              <div class="d-flex align-center">
                <v-spacer />
                <span class="mr-5">Total:</span>
                <div style="max-width: 200px">
                  <v-autocomplete
                    label="Currency"
                    :items="$store.state.currencies"
                    v-model="invoice.currency"
                    item-text="code"
                    item-value="code"
                  />
                </div>
              </div>
            </td>
            <td class="text-right">
              <strong>
                {{
                  invoice.items
                    .reduce((sum, item) => sum + item.quantity * item.price, 0)
                    .toFixed(2)
                }}
                {{ invoice.currency }}
              </strong>
            </td>
            <td></td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-card>

    <v-expansion-panels class="mb-5">
      <v-expansion-panel>
        <v-expansion-panel-header>
          Footer visible on invoice
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-textarea
            v-model="invoice.footer"
            label="Enter a footer for this invoice (e.g. tax information, thank you note)"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-card>
      <div class="pa-5">
        <v-radio-group v-model="invoice.toRealize" row class="shipping-options">
          <template slot="label">
            <span class="font-weight-black text-h5">Shipping options:</span>
          </template>
          <v-radio value="now" label="Send to execute now"></v-radio>
          <v-radio
            value="paid"
            label="Send to execute when invoice is paid"
          ></v-radio>
          <v-radio value="no" label="Invoice without shipping"></v-radio>
        </v-radio-group>
        <BillThirdParty v-model="invoice.billThirdParty" class="mb-1" />
        <v-textarea
          label="Comment visible only for shippment"
          v-model="invoice.comment"
          outlined
        ></v-textarea>
        <div class="d-flex">
          <v-spacer />
          <v-btn color="success" @click="createInvoice" :loading="createLoading"
            >Create invoice</v-btn
          >
        </div>
      </div>
    </v-card>

    <v-dialog v-model="newItemDialog.show" max-width="600px">
      <v-toolbar flat>
        <v-toolbar-title>Select product</v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="newItemDialog.show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-sheet class="pa-5">
        <div
          v-if="newItemDialog.choice === null"
          class="d-flex justify-space-around"
        >
          <v-sheet
            outlined
            @click="newItemDialog.choice = 'from-list'"
            class="pa-2 text-center green--text"
            style="cursor: pointer"
          >
            Select product from the list<br />
            <v-icon style="font-size: 8em" color="green"
              >mdi-format-list-text</v-icon
            >
          </v-sheet>
          <v-sheet
            outlined
            @click="newItemDialog.choice = 'manually-add'"
            class="pa-2 text-center blue--text"
            style="cursor: pointer"
          >
            Add item without product<br />
            <v-icon style="font-size: 8em" color="blue"
              >mdi-alphabetical</v-icon
            >
          </v-sheet>
        </div>
        <div v-if="newItemDialog.choice === 'from-list'">
          <v-autocomplete
            ref="addItemAc"
            label="Select product..."
            v-model="newItemDialog.product"
            :search-input.sync="newItemDialog.q"
            :items="newItemDialog.products"
            item-value="id"
            item-text="pName"
            :filter="customFilter"
          >
            <template v-slot:item="data">
              <template v-if="typeof data.item !== 'object'">
                <v-list-item-content v-text="data.item"></v-list-item-content>
              </template>
              <template v-else>
                <v-list-item-avatar rounded="false">
                  <img :src="data.item.thumbnail" />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ data.item.pName }}
                    <v-chip
                      v-if="data.item.mName"
                      color="pink"
                      dark
                      class="font-weight-black"
                      small
                    >
                      {{ data.item.mName }}
                    </v-chip>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    Barcodes: {{ data.item.eans.map(x => x.ean).join(", ") }},
                    PN:
                    {{ data.item.pn }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <ProductQty :product="data.item" />
              </template>
            </template>
          </v-autocomplete>
          <v-alert
            v-if="
              newItemDialog.product &&
                !newItemDialog.products.filter(
                  x => x.id === newItemDialog.product
                )[0].mId &&
                newItemDialog.products.filter(
                  x => x.id === newItemDialog.product
                )[0].hasModels
            "
            type="error"
            >Selected product has models. You should select model instead of
            product</v-alert
          >
          <v-btn
            v-if="newItemDialog.product || newItemDialog.name"
            @click="addNewItem"
          >
            Add
          </v-btn>
        </div>
        <div v-if="newItemDialog.choice === 'manually-add'">
          <div class="red--text font-weight-bold">
            AVOID THIS IF YOU CAN!
          </div>
          <v-text-field
            v-model="newItemDialog.name"
            label="Name"
          ></v-text-field>
          <v-text-field
            v-model="newItemDialog.description"
            label="Desc"
          ></v-text-field>
          <v-btn
            v-if="newItemDialog.product || newItemDialog.name"
            @click="addNewItem"
          >
            Add
          </v-btn>
        </div>
      </v-sheet>
    </v-dialog>
    <v-dialog v-model="addCustomer.show" max-width="600px">
      <v-toolbar flat>
        <v-toolbar-title>Select customer</v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="addCustomer.show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-sheet class="pa-5">
        <div class="font-weight-black">Select existing customer:</div>
        <v-autocomplete
          v-model="invoice.customer"
          :search-input.sync="addCustomer.q"
          :items="addCustomer.customers"
          :loading="addCustomer.loading"
          return-object
          item-text="name"
          label="Select customer"
          @change="addCustomer.show = false"
        >
          <template v-slot:item="data">
            <v-list-item-content>
              <v-list-item-title v-html="data.item.name"></v-list-item-title>
              <v-list-item-subtitle style="font-size: 0.8em">
                <AddressRender :address="data.item.address" />
              </v-list-item-subtitle>
            </v-list-item-content>
            <div class="mx-2 text--disabled text-body-2">
              invoices: {{ data.item.invoicesCount }}
            </div>
            <div class="text--disabled">
              updated:
              {{
                data.item.updatedAt
                  ? moment(data.item.updatedAt).format("ll")
                  : "never"
              }}
            </div>
          </template>
        </v-autocomplete>
        <div class="font-weight-black">or:</div>
        <v-btn
          depressed
          color="green"
          small
          @click="newCustomerDialog.show = true"
          >Add new customer</v-btn
        >
      </v-sheet>
    </v-dialog>
    <v-dialog v-model="newCustomerDialog.show" max-width="600px">
      <v-toolbar flat>
        <v-toolbar-title>Add new customer</v-toolbar-title>
        <v-spacer />
        <v-btn
          icon
          @click="
            newCustomerDialog.customer = null;
            newCustomerDialog.show = false;
          "
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-sheet class="pa-5">
        <CustomerForm
          v-model="newCustomerDialog.customer"
          @input="newCustomerSave()"
        />
      </v-sheet>
    </v-dialog>
    <v-dialog v-model="editCustomer.show" max-width="600px">
      <v-toolbar flat>
        <v-toolbar-title>Edit customer</v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="editCustomer.show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-sheet class="pa-5">
        <CustomerForm
          v-if="editCustomer.customer"
          v-model="editCustomer.customer"
          @input="saveCustomer"
        />
      </v-sheet>
    </v-dialog>
  <v-overlay v-if="fromShopOrderLoading">
    <v-progress-circular indeterminate />
  </v-overlay>
  </div>
</template>

<script>
import InvoiceQty from "./InvoiceQty";
import axios from "axios";
import globals from "../mixins/globals";
import functions from "../mixins/functions";
import AddressRender from "./AddressRender";
import CustomerForm from "./CustomerForm";
import moment from "moment";
import ProductQty from "./ProductQty";
import CustomerAddress from "./CustomerAddress";
import ShipmentSize from "./ShipmentSize";
import BillThirdParty from "./BillThirdParty";

export default {
  name: "InvoiceForm",
  components: {
    BillThirdParty,
    ShipmentSize,
    CustomerAddress,
    ProductQty,
    CustomerForm,
    AddressRender,
    InvoiceQty
  },
  mixins: [globals, functions],
  created() {
    if(this.$route.query.fromShopOrder) {
      this.fromShopOrderLoading = true;
    }

    axios.get(this.globals.apiUrl + "/product/list").then(res => {
      this.newItemDialog.products = res.data;

      if(this.$route.query.fromShopOrder) {
        this.invoice.fromShopOrder = this.$route.query.fromShopOrder
        axios.get(this.globals.apiUrl + "/shop-orders/order/"+this.$route.query.fromShopOrder).then(res => {
          this.invoice.customer = res.data.customer
          res.data.items.forEach(item => {
            this.newItemDialog.product = item.product.id
            this.addNewItem()
            this.invoice.items[this.invoice.items.length - 1].price = item.price
            this.invoice.items[this.invoice.items.length - 1].quantity = item.qty
          })
          if(res.data.shippingCost > 0) {
            this.invoice.items.push({
              ...this.shippingBase(),
              price: res.data.shippingCost
            })
          }
        }).finally(() => {
          this.fromShopOrderLoading = false
        })
      }
    });

    window._bc.handler = this.barcodeScanned;
  },
  watch: {
    "newItemDialog.show": {
      handler() {
        this.newItemDialog.choice = null;
      }
    },
    "addCustomer.q": {
      handler(q) {
        if (this.addCustomer.cancelToken) {
          this.addCustomer.cancelToken.cancel();
        }

        this.addCustomer.cancelToken = axios.CancelToken.source();

        this.addCustomer.loading = true;
        axios
          .post(
            this.globals.apiUrl + "/customer/list",
            {
              q,
              page: 1
            },
            {
              cancelToken: this.addCustomer.cancelToken.token
            }
          )
          .then(res => {
            this.addCustomer.customers = res.data.customers;
          })
          .catch(err => console.log(err))
          .finally(() => {
            this.addCustomer.loading = false;
            this.addCustomer.cancelToken = null;
          });
      }
    }
  },
  data() {
    return {
      fromShopOrderLoading: false,
      newCustomerDialog: {
        show: false,
        customer: null
      },
      addCustomer: {
        show: false,
        q: "",
        loading: false,
        customers: [],
        cancelToken: null
      },
      editCustomer: {
        show: false,
        customer: null
      },
      newItemDialog: {
        products: [],
        choice: false,
        show: false,
        product: null,
        name: null,
        description: null,
        q: ""
      },
      invoice: {
        customer: null,
        currency: "USD",
        title: "Invoice",
        subhead: "",
        invoiceNumber: "",
        poNumber: "",
        invoiceDate: moment().format("YYYY-MM-DD"),
        dueDate: moment().format("YYYY-MM-DD"),
        items: [],
        memo: "",
        footer: "",
        toRealize: null,
        comment: null,
        differentShippingAddress: {
          different: false,
          name: "",
          phone: "",
          address: null
        },
        billThirdParty: null
      },
      createLoading: false
    };
  },
  methods: {
    calculateShipping(item) {
      this.$store.commit("showLoading");
      this.$set(item, "shippingChoices", []);
      axios
        .post(this.globals.apiUrl + "/ups/calculate", {
          invoice: this.invoice,
          item
        })
        .then(res => {
          item.shippingChoices = res.data;
          this.notyf("Shipping calculated");
        })
        .catch(res => {
          if (res && res.response.data) {
            this.notyf(res.response.data.message, "error");
          }
        })
        .finally(() => {
          this.$store.commit("hideLoading");
        });
    },
    shippingBase() {
      return {
        product: null,
        price: 10,
        name: "Shipping",
        description: "UPS - GROUND",
        eans: [],
        shipping: true,
        shippingDimensionsShow: false,
        shippingDimensions: null
      };
    },
    newCustomerSave() {
      this.$store.commit("showLoading");
      axios
        .post(this.globals.apiUrl + "/customer/update", {
          customer: this.newCustomerDialog.customer
        })
        .then(res => {
          this.addCustomer.show = false;
          this.newCustomerDialog.show = false;
          this.invoice.customer = res.data.customer;
        })
        .catch(reason => {
          if (reason.response && reason.response.data) {
            this.notyf(reason.response.data.message, "error");
          }
        })
        .finally(() => {
          this.$store.commit("hideLoading");
        });
    },
    barcodeScanned(barcode) {
      this.newItemDialog.show = true;
      setTimeout(() => {
        this.newItemDialog.q = barcode;
        this.$refs.addItemAc.$refs.input.click();
      }, 300);
    },
    createInvoice() {
      if (!this.invoice.toRealize) {
        this.notyf("Select one of shipping options", "error");
        return;
      }

      if (!this.invoice.customer) {
        this.notyf("select customer", "error");
        return;
      }

      if (this.invoice.items.length === 0) {
        this.notyf("add items to invoice", "error");
        return;
      }

      this.createLoading = true;

      axios
        .post(this.globals.apiUrl + "/invoices/create", {
          invoice: this.invoice
        })
        .then(res => {
          this.notyf("Invoice created");
          localStorage.setItem("show-invoice", res.data.id);
          this.$router.push({ name: "invoices" });
        })
        .catch(err => {
          if (err.response && err.response.data && err.response.data.message) {
            this.notyf(err.response.data.message, "error");
          } else {
            this.notyf("Create error", "error");
          }
        })
        .finally(() => {
          this.createLoading = false;
        });
    },
    saveCustomer() {
      axios
        .post(this.globals.apiUrl + "/customer/update", {
          customer: this.editCustomer.customer
        })
        .then(res => {
          this.invoice.customer = res.data.customer;
          this.notyf("Update success", "success");
          this.editCustomer.show = false;
        })
        .catch(() => {
          this.notyf("Update failed", "error");
        });
    },
    addNewItem() {
      const p = this.newItemDialog.products.filter(
        x => x.id === this.newItemDialog.product
      )[0];
      this.invoice.items.push({
        ...this.newItem(),
        product: p ? p.id : null,
        price: p && p.msrp ? p.msrp : 1,
        name: p ? p.pn : this.newItemDialog.name,
        description: p ? p.pName : this.newItemDialog.name,
        eans: p
          ? p.eans.map((x, i) => {
              return {
                ...x,
                selected: i === 0
              };
            })
          : []
      });
      this.newItemDialog.show = false;
    },
    newItem() {
      return {
        product: null,
        name: "",
        description: "",
        quantity: 1,
        price: 1
      };
    },
    customFilter(item, q) {
      if (q.includes("/product/") && q.includes(".html")) {
        const matches = /\.html\/(\d+)/.exec(q);
        if (matches) {
          return item.mId == matches[1];
        }

        q = q.split("/product/")[1];
        q = q.split(".html")[0];
      }

      const searchStr = (
        item.pName +
        item.eans.map(x => x.ean).join(", ") +
        item.pn +
        item.slug
      ).toLowerCase();
      return searchStr.includes(q.toLowerCase());
    }
  }
};
</script>

<style scoped lang="scss">
.shipping-options {
  .v-radio {
    border: 1px solid #8d8d8d;
    border-radius: 4px;
    padding: 10px;
    &.v-item--active {
      border-color: #1976d2;
    }
  }
}
</style>
